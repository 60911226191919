import { mergeWith } from 'lodash'
import type { PluginObject } from 'vue'

import { type ConstantsDefinition, constants } from 'innicore/plugins/constants'

interface ClientConstantsDefinition {
  _placeholder?: never
}

const clientConstants: DeepPartial<ConstantsDefinition> & ClientConstantsDefinition = {
  appName: 'Lienesch',
  select_by: {
    // Fill this with possible client-specific selector options
    select_options: [
      { value: 'trusted_supplier', text: 'Supplier' },
      { value: 'shipment_status', text: 'Status' },
    ],
  },
}

const arrayMerge = (objValue: unknown, srcValue: unknown) => {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return [...objValue, ...srcValue]
  }
}

// Merge the two select_by.select_options arrays
mergeWith(constants, clientConstants, arrayMerge)
constants.select_by.select_values = constants.select_by.select_options.map((option) => option.value)

export { constants }

export const Constants: PluginObject<void> = {
  install: (Vue) => {
    Vue.prototype.$constants = constants
  },
}
